import {useMutation, useQuery, gql} from '@apollo/client';
import {useAuth} from './AuthContext';
import LoadingLine from './LoadingLine';
import RobotSummary from './RobotSummary';
import RobotCards from './RobotCards';
import {
  filterRobot,
  getStatistics,
  getIndividualStates
} from '../utils/filter';
import {postSlack} from '../utils/slack';
import {defaultBackgroundColor} from '../utils/color';

const QUERY = gql`
  query Query {
    getAllRobots {
      id
      serial_number
      history {
        id
        operator_id
        state
        name
        location
        log
        created_at
      }
      tag {
        id
        description
      }
      event {
        id
        name
        start_date
        end_date
      }
      component {
        id
        description
        serial_number
      }
      software {
        id
        description
        version
      }
    }
  }
`;

const CONSTRUCT = gql`
  mutation Mutation($operator: String!, $count: Int!, $name: String) {
    constructRobots(operator: $operator, count: $count, name: $name) {
      id
      serial_number
    }
  }
`;

const RobotTable = ({
  onClick,
  filterModel, onFilterModelChange,
  sortModel, onSortModelChange,
  searchKeyword, onSearchKeywordChange,
  searchCategory, onSearchCategoryChange,
  multiMode, onChangeMultiMode, multiRobots,
  onEditMulti,
}) => {
  const {getEmail} = useAuth();
  const {loading, error, data} = useQuery(QUERY);
  const [constructRobots] = useMutation(CONSTRUCT, {
    refetchQueries: ['Query'],
    onCompleted: ({constructRobots: robots}) => {
      const contents = robots.map(i => i.serial_number).join('\n');
      postSlack(getEmail(), `constructed\n${contents}`);
    }
  });
  const onConstruct = (count, name) => {
    constructRobots({variables: {operator: getEmail(), count: count, name: name}});
  };
  if (loading) return <LoadingLine />;
  if (error) return <p>Error :(</p>;
  const robots = data?.getAllRobots ?? [];
  const [states, operators, locations, tags] = getStatistics(robots);
  const filteredRobots = filterRobot(robots, filterModel, searchKeyword, searchCategory);
  const filteredStates = getIndividualStates(filteredRobots);
  return (
    <div
      style={{
        width: '100%',
        backgroundColor: defaultBackgroundColor,
        display: 'flex'
      }}
    >
      <RobotSummary
        filteredStates={filteredStates}
        operators={operators}
        states={states}
        locations={locations}
        tags={tags}
        filterModel={filterModel}
        onFilterModelChange={onFilterModelChange}
        onConstruct={onConstruct}
      />
      <RobotCards
        robots={filteredRobots}
        onClick={onClick}
        sortModel={sortModel}
        onSortModelChange={onSortModelChange}
        searchKeyword={searchKeyword}
        onSearchKeywordChange={onSearchKeywordChange}
        searchCategory={searchCategory}
        onSearchCategoryChange={onSearchCategoryChange}
        multiMode={multiMode}
        onChangeMultiMode={onChangeMultiMode}
        multiRobots={multiRobots}
        onEditMulti={onEditMulti}
      />
    </div>
  );
};

export default RobotTable;

// vim: set expandtab shiftwidth=2:
