import {useState} from 'react';
import {useMutation, useQuery, gql} from '@apollo/client';
import {
  Button,
  Grid,
  Typography
} from '@mui/material';
import {useAuth} from './AuthContext';
import LoadingLine from './LoadingLine';
import DialogLocation from './DialogLocation';
import {getLocations} from '../utils/locations';
import {postSlack} from '../utils/slack';

const QUERY = gql`
  query Query {
    getAllRobots {
      id
      serial_number
      history {
        location
      }
    }
  }
`;

const SET = gql`
  mutation Mutation($operator: String!, $id: ID!, $location: String!) {
    setLocation(operator: $operator, id: $id, location: $location) {
      id
      serial_number
      history {
        id
        state
        name
        location
        log
        created_at
      }
    }
  }
`;

const RobotDetailHeaderLocation = ({mode, robot}) => {
  const [open, setOpen] = useState(false);
  const {getEmail} = useAuth();
  const {loading, error, data} = useQuery(QUERY);
  const [setLocation] = useMutation(SET, {
    onCompleted: ({setLocation: robot}) => {
      const history = robot?.history?.[0];
      const target = `${history?.name ?? ''} / ${robot?.serial_number}`;
      const contents = `location changed to: ${history?.location}`;
      postSlack(getEmail(), `${target}: ${contents}`);
    }
  });
  if (loading) return <LoadingLine />;
  if (error) return <p>Error :(</p>;
  const onUpdate = location => {
    setLocation({variables: {operator: getEmail(), id: robot.id, location: location}});
    setOpen(false);
  };
  const locations = getLocations(data?.getAllRobots ?? []);
  const location = robot.history?.[0].location ?? '';
  return (
    <>
      <Grid container alignItems="center" spacing={2}>
        <Grid item xs={2}>
          <Typography variant="body1" align="right">Location:</Typography>
        </Grid>
        <Grid item xs={5}>
          <Typography variant="body1">
            {location}
          </Typography>
        </Grid>
        <Grid item xs={1}>
          <Button onClick={() => setOpen(true)}>Update</Button>
        </Grid>
      </Grid>
      <DialogLocation
        open={open}
        onCancel={() => setOpen(false)}
        onOk={onUpdate}
        locations={locations}
        location={location}
      />
    </>
  );
};

export default RobotDetailHeaderLocation;

// vim: set expandtab shiftwidth=2:
