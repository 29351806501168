import {useState} from 'react';
import {useQuery, gql} from '@apollo/client';
import KarteBar from './KarteBar';
import LoadingLine from './LoadingLine';
import RobotSummary from './RobotSummary';
import RobotTimelineDetail from './RobotTimelineDetail';
import RobotDetail from './RobotDetail';
import { CircleSpinnerOverlay } from 'react-spinner-overlay'
import {
  filterRobot,
  getStatistics,
  getIndividualStates,
  SearchFilterCategory,
} from '../utils/filter';
import {
  locationColor, 
  defaultBackgroundColor, 
  semiTransparentColor
} from '../utils/color';

const QUERY = gql`
  query Query {
    getAllRobots {
      id
      history {
        operator_id
        state
        name
        location
      }
      event {
        id
        name
        start_date
        end_date
      }
      tag {
        description
      }
    }
  }
`;

const RobotTimeline = () => {
  const [showTimeline, setShowTimeline] = useState(true);
  const [robotIds, setRobotIds] = useState([]);
  const [filterModel, setFilterModel] = useState({});
  const [sortModel, setSortModel] = useState({field: 'name', order: 'asc'});
  const [searchKeyword, setSearchKeyword] = useState('');
  const [isUpdateEvent, setIsUpdateEvent] = useState(false);
  const [searchCategory, setSearchCategory] = useState(SearchFilterCategory.All);
  const {loading, error, data, refetch} = useQuery(QUERY, {
    onCompleted: () =>{
      setIsUpdateEvent(false);
    }
  });
  if (loading) return <LoadingLine />;
  if (error) return <p>Error :(</p>;
  const robots = data.getAllRobots ?? [];
  const [states, operators, locations, tags] = getStatistics(robots);
  const filteredRobots = filterRobot(robots, filterModel, searchKeyword, searchCategory);
  const filteredStates = getIndividualStates(filteredRobots);
  const onBackClick = () => {
    setShowTimeline(true);
  };
  const onRobotClick = robotId => {
    setRobotIds([robotId]);
    setShowTimeline(false);
  };
  locations.forEach(i => locationColor(i));
  const contents = showTimeline ? <div style={{
    width: '100%',
    backgroundColor: defaultBackgroundColor,
    display: 'flex'
  }}>
    <CircleSpinnerOverlay loading={isUpdateEvent} overlayColor={semiTransparentColor}></CircleSpinnerOverlay>
    <RobotSummary
      filteredStates={filteredStates}
      operators={operators}
      states={states}
      locations={locations}
      tags={tags}
      filterModel={filterModel}
      onFilterModelChange={setFilterModel}
    />
    <RobotTimelineDetail
      robots={filteredRobots}
      locations={locations}
      sortModel={sortModel}
      onSortModelChange={setSortModel}
      searchKeyword={searchKeyword}
      onSearchKeywordChange={setSearchKeyword}
      searchCategory={searchCategory}
      onSearchCategoryChange={setSearchCategory}
      onTimelineUpdate={() => refetch()}
      setIsUpdateEvent={setIsUpdateEvent}
      onRobotClick={onRobotClick}
    />
  </div> : <RobotDetail id={robotIds[0]} onClick={onBackClick} />;
  return (
    <>
      <KarteBar />
      {contents}
    </>
  );
};

export default RobotTimeline;
